@import '~bootstrap/scss/bootstrap-utilities';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/_forms';
@import '~bootstrap/scss/_buttons';

.nb-theme-default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #303030;
    line-height: 1.3;
    font-weight: 500;
  }
  nb-layout {
    .layout {
      background-color: #fff;
    }
  }
  a {
    color: inherit;
    text-decoration: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    &.active {
      color: red;
    }
    &:hover {
      color: unset;
    }
  }

  p {
    color: inherit;
  }
}
.bg-white {
  background-color: #fff;
}

.ant-btn {
  .anticon {
    vertical-align: 0;
  }
}

i[nztype='delete'] {
  color: #e74c3c;
}

::ng-deep .ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #1890ff !important;
}
